import PropTypes from 'prop-types';
import { t } from 'bv-i18n';

const ReadMoreLink = ({ url, onReadMoreLinkClick }) => {
  const title = t('javascript.read_more');

  return (
    <div className="consent-links">
      {url ? (
        <a target="_blank" rel="noopener noreferrer" href={url}>
          {title}
        </a>
      ) : (
        <span onClick={onReadMoreLinkClick}>
          {title}
        </span>
      )}
    </div>
  );
};

ReadMoreLink.propTypes = {
  url: PropTypes.string.isRequired,
  onReadMoreLinkClick: PropTypes.func.isRequired,
};

export default ReadMoreLink;
