import { Component } from 'react';
import PropTypes from 'prop-types';
import { sanitize } from 'dompurify';
import { t } from 'bv-i18n';
import { Modal } from 'bv-components';

class ConsentModal extends Component {
  constructor(props) {
    super(props);
    this.onCheckBoxChanged = this.onCheckBoxChanged.bind(this);
    this.onButtonClick = this.onButtonClick.bind(this);
    this.onSecondaryButtonClick = this.onSecondaryButtonClick.bind(this);

    this.state = {
      checked: false,
      modalIsOpened: true,
    };
  }

  onCheckBoxChanged() {
    this.setState((prevState) => ({
      checked: !prevState.checked,
    }));
  }

  onButtonClick() {
    const { onPrimaryClick } = this.props;
    onPrimaryClick();
    this.afterClick();
  }

  onSecondaryButtonClick() {
    const { onSecondaryClick } = this.props;
    onSecondaryClick();
    this.afterClick();
  }

  afterClick() {
    this.setState({ modalIsOpened: false });
  }

  render() {
    const { checked, modalIsOpened } = this.state;

    if (!modalIsOpened) return null;

    const {
      title, body, children, modalClassName, checkBoxText, primaryButtonText, secondaryButtonText,
    } = this.props;

    return (
      <Modal
        info
        title={title()}
        actions={[{
          primary: true,
          label: primaryButtonText,
          disabled: !checked,
          onClick: this.onButtonClick,
        }, {
          primary: true,
          label: secondaryButtonText,
          onClick: this.onSecondaryButtonClick,
        }]}
        modalClassName={modalClassName}
      >
        {body()}
        {children}
        <p className="consent-form-row">
          <input
            type="checkbox"
            id="subscribeNews"
            name="subscribe"
            value="newsletter"
            className="consent-checkbox"
            onChange={this.onCheckBoxChanged}
          />
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label htmlFor="subscribeNews" className="consent-label">
            <span dangerouslySetInnerHTML={{ __html: sanitize(checkBoxText) }} />
          </label>
        </p>
      </Modal>
    );
  }
}

ConsentModal.propTypes = {
  title: PropTypes.func.isRequired,
  body: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  primaryButtonText: PropTypes.string,
  secondaryButtonText: PropTypes.string,
  onPrimaryClick: PropTypes.func.isRequired,
  onSecondaryClick: PropTypes.func,
  checkBoxText: PropTypes.string.isRequired,
  modalClassName: PropTypes.string,
};

ConsentModal.defaultProps = {
  primaryButtonText: t('javascript.accept'),
  secondaryButtonText: t('javascript.logout'),
  onSecondaryClick: () => {},
  modalClassName: '',
};

export default ConsentModal;
