import PropTypes from 'prop-types';
import { v as bvVar } from 'bv';
import ConsentModal from '../consent_modal/components/consent_modal';
import ReadMoreLink from './components/read_more_link';
import { body, checkBoxText, title } from './helpers';

const App = (props) => {
  const {
    url,
    onReadMoreLinkClick,
    onPrimaryClick,
    primaryButtonText,
    onSecondaryClick,
    secondaryButtonText,
  } = props;

  return (
    <ConsentModal
      title={title}
      body={body}
      checkBoxText={checkBoxText()}
      onPrimaryClick={onPrimaryClick}
      onSecondaryClick={onSecondaryClick}
      primaryButtonText={primaryButtonText}
      secondaryButtonText={secondaryButtonText}
    >
      {bvVar('showProtectionFundsReadMore') && (
        <ReadMoreLink url={url} onReadMoreLinkClick={onReadMoreLinkClick} />
      )}
    </ConsentModal>
  );
};

App.propTypes = {
  url: PropTypes.string.isRequired,
  onReadMoreLinkClick: PropTypes.bool.isRequired,
  onPrimaryClick: PropTypes.func.isRequired,
  primaryButtonText: PropTypes.string.isRequired,
  onSecondaryClick: PropTypes.func.isRequired,
  secondaryButtonText: PropTypes.string.isRequired,
};

export default App;
